import { CommonModule } from '@angular/common';
import { HttpClient, HttpClientModule, HttpHeaders, HttpEventType, HttpEvent } from '@angular/common/http';
import { Component, OnInit, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { saveAs } from 'file-saver';
import { FormsModule } from '@angular/forms';
import { catchError, finalize, takeUntil, tap } from 'rxjs/operators';
import { of, timer, Subject } from 'rxjs';
@Component({
  selector: 'app-user-profile',
  standalone: true,
  imports: [CommonModule, HttpClientModule, FormsModule],
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.css']
})
export class UserProfileComponent implements OnInit {
  imageurl = 'assets/images/download.jpg';
  selectedFile: File | null = null;
  imageurld = 'assets/images/download.jpg';
  attri = 'original-image w-full md:w-1/2 lg:w-5/12 hidden';
  uploadfileDiv = 'fw-full md:w-1/2 lg:w-1/3 p-4';
  bearerToken = 1235; // Add your bearer token
  selectedOption: string = '1';
  isDisabled = false;
  preview = 'w-full md:w-1/2 lg:w-1/3 p-4 hidden';
  closeButtonicon = 'absolute  bg-white rounded-full p-1 focus:outline-none';
  previewimage = 'original-image w-full md:w-1/2 lg:w-5/12 hidden';
  fileTooLarge = false;
  loading = false;
  progress = 0;
  errorMessage = '';
  showContactPopup: boolean = false;
  @ViewChild('enhancedImage') enhancedImage!: ElementRef;
  constructor(private http: HttpClient) { }

  ngOnInit(): void { }

  onchnageprofile(event: Event): void {
    const input = event.target as HTMLInputElement;
    this.isDisabled = false;
    if (input.files && input.files[0]) {
      const file = input.files[0];
      const fileSizeMB = file.size / (1024 * 1024);
      if (fileSizeMB > 5) {
        this.fileTooLarge = true;
        this.selectedFile = null;
        return;
      }
      this.fileTooLarge = false;

      this.selectedFile = input.files[0];
      const reader = new FileReader();
      reader.onload = (e: ProgressEvent<FileReader>) => {
        this.imageurl = e.target?.result as string;
        this.preview = 'w-full md:w-1/2 lg:w-1/3 p-4';
      };
      reader.readAsDataURL(input.files[0]);
    }
  }
  fetchdetails(): void {
    if (this.selectedFile) {
      this.scrollToEnhancedImage();
      this.attri = 'original-image w-full md:w-1/2 lg:w-5/12';
      this.isDisabled = true;
      this.loading = true;
      
      this.progress = 0; // Start at 0%
     
      this.errorMessage = '';
      // this.enhancedImage.nativeElement.scrollIntoView({ behavior: 'smooth' });
      const formData = new FormData();
      formData.append('file', this.selectedFile, this.selectedFile.name);
      this.preview = 'w-full md:w-1/2 lg:w-1/3 p-4 hidden';

      const headers = new HttpHeaders().set('Authorization', `Bearer ${this.bearerToken}`);

      const progressSubject = new Subject<void>();
      const totalDuration = 60000; // 60 seconds total
      const intervalDuration = 100; // Update every 100ms for smoother animation
      let startTime: number;

      const progressTimer = timer(0, intervalDuration).pipe(
        takeUntil(progressSubject),
        tap(() => {
          if (!startTime) startTime = Date.now();
          const elapsedTime = Date.now() - startTime;
          const progressPercentage = Math.min((elapsedTime / totalDuration) * 100, 80);
          this.progress = Math.floor(progressPercentage);

          if (this.progress >= 80) {
            progressSubject.next();
            progressSubject.complete();
          }
        })
      );

      progressTimer.subscribe();
      
      this.http.post(`/api/process_image/?action=${this.selectedOption}`, formData, {
        headers,
        reportProgress: true,
        observe: 'events'
      }).pipe(
        catchError(error => {
          console.error('Upload failed', error);
          this.errorMessage = 'Upload failed. Please try again.';
          return of(null);
        }),
        finalize(() => {
          this.loading = false;
          this.progress = 100; // Ensure progress is 100% when finished
          progressSubject.next();
          progressSubject.complete();
        })
      ).subscribe(
        (event: any) => {
          if (event === null) return; // Error occurred

          if (event.type === HttpEventType.Response) {
            // Final response after the file has been processed
            
            const response = event.body;
            const imageFormat = response.data['image_format'] || 'jpg';
            this.imageurld = `data:image/${imageFormat};base64,${response.data['image_base64']}`;

            this.attri = 'original-image w-full md:w-1/2 lg:w-5/12';

            if (this.imageurld) {
              this.previewimage = 'original-image w-full md:w-1/2 lg:w-5/12';
              this.uploadfileDiv = 'fw-full md:w-1/2 lg:w-1/3 p-4';
              
            }

            // Set progress to 100% when response is received
            this.progress = 100;
            progressSubject.next();
            progressSubject.complete();
            // this.openContactUsDialog();
           

          }
        }
      );
    } else {
      console.warn('No file selected');
      this.errorMessage = 'Please select a file before uploading.';
    }
  }


  // fetchdetails(): void {
  //   if (this.selectedFile) {
  //     this.attri = 'original-image w-full md:w-1/2 lg:w-5/12';
  //     this.isDisabled = true;
  //     this.loading = true;
  //     const formData = new FormData();
  //     formData.append('file', this.selectedFile, this.selectedFile.name);
  //     this.preview = 'w-full md:w-1/2 lg:w-1/3 p-4 hidden';
  //     const headers = new HttpHeaders().set('Authorization', `Bearer ${this.bearerToken}`);
  //     // this.closeButtonicon = 'hidden';
  //     this.http.post(`/api/process_image/?action=${this.selectedOption}`, formData, { headers })
  //       .subscribe(
  //         (response: any) => {
  //           console.log(response);

  //           const imageFormat = response.data['image_format'] || 'jpg';
  //           this.imageurld = `data:image/${imageFormat};base64,${response.data['image_base64']}`;

  //           this.loading = false;
  //           this.attri = 'original-image w-full md:w-1/2 lg:w-5/12';
  //           if (this.imageurld) {
  //             this.previewimage = 'original-image w-full md:w-1/2 lg:w-5/12';
  //             this.uploadfileDiv = 'fw-full md:w-1/2 lg:w-1/3 p-4';
  //           }
  //         },
  //         (error) => {
  //           console.error('Upload failed', error);
  //           this.loading = false;
  //         }
  //       );
  //   } else {
  //     console.warn('No file selected');
  //   }
  // }

  downloadImage(): void {
    if (this.imageurld) {
      const parts = this.imageurld.split(';base64,');
      const imageType = parts[0].split(':')[1];
      const base64Data = parts[1];
      const byteCharacters = atob(base64Data);
      const byteArrays = [];

      for (let offset = 0; offset < byteCharacters.length; offset += 512) {
        const slice = byteCharacters.slice(offset, offset + 512);
        const byteNumbers = new Array(slice.length);
        for (let i = 0; i < slice.length; i++) {
          byteNumbers[i] = slice.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        byteArrays.push(byteArray);
      }

      const blob = new Blob(byteArrays, { type: imageType });
      const fileName = `downloaded_image.${imageType.split('/')[1]}`;
      saveAs(blob, fileName);
      this.openContactUsPopup();
    } else {
      console.warn('No image to download');
    }
  }
  openContactUsPopup() {
    this.showContactPopup = true;
    setTimeout(() => {
      document.querySelector('.popup-overlay')?.classList.add('animated-popup');
    }, 10); // Small delay to trigger CSS transition
  }

  closeContactUsPopup() {
    const popupOverlay = document.querySelector('.popup-overlay');
    if (popupOverlay) {
      popupOverlay.classList.remove('animated-popup');
      setTimeout(() => {
        this.showContactPopup = false;
      }, 300); // Time for CSS transition to complete
    }
  }
  closeButton(): void {
    this.preview = 'hidden';
    this.isDisabled = true;
  }
  scrollToEnhancedImage() {
    if (this.enhancedImage) {
      this.enhancedImage.nativeElement.scrollIntoView({ behavior: 'smooth' });
    }
  }
}
